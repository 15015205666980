export class SpeedFFTable {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Speed FFTable";
    this.scene = "Speed FFTable";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];
    this.data.push({
      name: "Competition Title",
      value: {
        text: options?.game?.competition?.name || "",
      },
    });

    this.data.push({
      name: "Category Title",
      value: {
        text:
          options?.game?.description
            ?.split(" - ")?.[0]
            ?.replace("SPEED Women", "WOMEN'S SPEED")
            ?.replace("SPEED Men", "MEN'S SPEED") || "",
      },
    });

    this.data.push({
      name: "Category subtitle",
      value: {
        text: options?.game?.description?.split(" - ")?.[1] || "",
      },
    });
    let results;
    if (options.web) {
      results = options.game?.results;
    } else {
      results = options.ranks?.map((rank) => {
        let obj = options.game?.manual_results?.find(
          (p) => parseInt(p.athlete_id) === parseInt(rank?.athlete_id)
        );

        return { ...obj, rank: rank?.rank };
      });
    }

    new Array(10).fill(1)?.map((_, index) => {
      let result = results[index];
      if (options.page === 2) {
        result = results[index + 10];
      } else if (options.page === 3) {
        result = results[index + 20];
      }

      let athlete = options?.game?.groups?.[0]?.routes?.[0]?.athletes?.find(
        (a) => parseInt(a?.athlete?.ifsc_id) === parseInt(result?.athlete_id)
      );

      let row = index + 1;
      this.data.push({
        name: row + " Athlete First Name",
        value: {
          text: athlete?.athlete?.first_name || "",
        },
      });
      this.data.push({
        name: row + " Athlete Last Name",
        value: {
          text: athlete?.athlete?.last_name || "",
        },
      });
      this.data.push({
        name: row + " Athlete Full Name",
        value: {
          text:
            (athlete?.athlete?.first_name || "") +
            " " +
            (athlete?.athlete?.last_name || ""),
        },
      });
      this.data.push({
        name: row + " Athlete Country",
        value: {
          text: athlete?.athlete?.country_of_birth || "",
        },
      });
      this.data.push({
        name: row + " Athlete Country Flag",
        value: {
          image: "Flags/" + athlete?.athlete?.country_of_birth + ".png",
        },
      });

      this.data.push({
        name: row + " Athlete Position",
        value: {
          text: result?.rank || "",
        },
      });
      this.data.push({
        name: row + " Athlete Bib",
        value: {
          text: result?.bib || "",
        },
      });

      this.data.push({
        name: row + " Athlete Time 1",
        value: {
          colour:
            result?.ascents?.find((a) => a?.route_name === "A")?.time_ms <
            result?.ascents?.find((a) => a?.route_name === "B")?.time_ms
              ? "#B38E4E"
              : "#FFFFFF",
          text: !result
            ? ""
            : result?.ascents?.find((a) => a?.route_name === "A")?.dns
            ? "DNS"
            : result?.ascents?.find((a) => a?.route_name === "A")?.dnf
            ? "DNF"
            : (result?.ascents?.find((a) => a?.route_name === "A")?.time_ms ||
                0) / 1000,
        },
      });

      this.data.push({
        name: row + " Athlete Time 2",
        value: {
          colour:
            result?.ascents?.find((a) => a?.route_name === "B")?.time_ms <
            result?.ascents?.find((a) => a?.route_name === "A")?.time_ms
              ? "#B38E4E"
              : "#FFFFFF",
          text: !result
            ? ""
            : result?.ascents?.find((a) => a?.route_name === "B")?.dns
            ? "DNS"
            : result?.ascents?.find((a) => a?.route_name === "B")?.dnf
            ? "DNF"
            : (result?.ascents?.find((a) => a?.route_name === "B")?.time_ms ||
                0) / 1000,
        },
      });
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
