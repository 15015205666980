import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";

import { ControlContext } from "../../contexts/ControlContext";
import { APIContext } from "../../contexts/APIContext";

import Web from "./Web";
import Manual from "./Manual";
import WebSpeed from "./WebSpeed";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Button = styled.div`
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  align-items: center;
  display: flex;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : ""};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  /* width: 100%; */
  justify-content: center;

  margin-top: 0.5em;
  padding: 0.5em;
`;
const Row = styled.div`
  display: flex;
`;

export default function Game({
  game,
  setSelectedPlayer,
  selectedPlayer,
  view,
  setView,
  ranks,
  setRanks,
  group,
  setGroup,
  previewGraphic,
  triggerAnimation,
  updateGraphic,
  routeName,
  setRouteName,
}) {
  const { clear } = useContext(ControlContext);
  const { updateGame } = useContext(APIContext);

  useEffect(() => {
    if (!group) {
      setGroup(game?.groups?.[0]);
    }
  }, [game, group]);
  return (
    <Main>
      <Row>
        {game?.groups?.map((g) => {
          return (
            <Button
              green={group?.name === g.name}
              onClick={() => {
                setGroup(g);
              }}
            >
              {g.name}
            </Button>
          );
        })}
      </Row>
      <Row>
        <Button
          green={view === 0}
          onClick={() => {
            setView(0);
            setSelectedPlayer();
          }}
        >
          Web
        </Button>
        <Button
          green={view === 1}
          onClick={() => {
            setView(1);
            setSelectedPlayer();
          }}
        >
          Manual
        </Button>
      </Row>
      {game?.round_type === "boulder" && (
        <>
          <Row>
            {view === 0 && (
              <Web
                selectedPlayer={selectedPlayer}
                setSelectedPlayer={setSelectedPlayer}
                game={game}
                group={group}
                ranks={ranks}
                setRanks={setRanks}
                updateGraphic={updateGraphic}
                routeName={routeName}
                setRouteName={setRouteName}
              />
            )}
            {view === 1 && (
              <Manual
                selectedPlayer={selectedPlayer}
                setSelectedPlayer={setSelectedPlayer}
                game={game}
                group={group}
                updateGame={updateGame}
                ranks={ranks}
                setRanks={setRanks}
                updateGraphic={updateGraphic}
                routeName={routeName}
                setRouteName={setRouteName}
              />
            )}
          </Row>
        </>
      )}
      {game?.round_type === "speed" && (
        <Row>
          {view === 0 && (
            <WebSpeed
              selectedPlayer={selectedPlayer}
              setSelectedPlayer={setSelectedPlayer}
              game={game}
              group={group}
              ranks={ranks}
              setRanks={setRanks}
              previewGraphic={previewGraphic}
              triggerAnimation={triggerAnimation}
            />
          )}
        </Row>
      )}
    </Main>
  );
}
