export class Winner {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Winner";
    this.scene = "Winner";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];
    this.data.push({
      name: "Competition Title",
      value: {
        text: options?.game?.competition?.name || "",
      },
    });
    this.data.push({
      name: "Category Title",
      value: {
        text: options?.game?.description?.split(" - ")?.[0] || "",
      },
    });

    this.data.push({
      name: "Category subtitle",
      value: {
        text: options?.game?.description?.split(" - ")?.[1] || "",
      },
    });

    this.data.push({
      name: "Athlete First Name",
      value: {
        text: options.player.first_name || "",
      },
    });
    this.data.push({
      name: "Athlete Last Name",
      value: {
        text: options.player.last_name || "",
      },
    });
    this.data.push({
      name: "Athlete Full Name",
      value: {
        text:
          (options.player.first_name || "") +
          " " +
          (options.player.last_name || ""),
      },
    });
    this.data.push({
      name: "Athlete Bib",
      value: {
        text: options.player.bib || "",
      },
    });

    this.data.push({
      name: "Athlete Country",
      value: {
        text: options.player.country_of_birth || "",
      },
    });
    this.data.push({
      name: "Athlete Country Flag",
      value: {
        image: "Flags/" + options.player.country_of_birth + ".png",
      },
    });
    this.data.push({
      name: "Athlete Age",
      value: {
        text: options.player.age || "",
      },
    });
    this.data.push({
      name: "Athlete Height",
      value: {
        text: options.player.height || "",
      },
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
