export class FFBracket {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "FFBracket";
    this.scene = "Speed FFBracket";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];

    this.data.push({
      name: "Competition Title",
      value: {
        text: options?.game?.competition?.name || "",
      },
    });
    debugger;
    this.data.push({
      name: "Category Title",
      value: {
        text:
          options?.game?.description
            ?.split(" - ")?.[0]
            ?.replace("SPEED Men", "MEN'S")
            ?.replace("SPEED Women", "WOMEN'S") || "",
      },
    });

    this.data.push({
      name: "Category subtitle",
      value: {
        text:
          options?.game?.description
            ?.split(" - ")?.[1]
            ?.replace("Final", "SPEED FINAL") || "",
      },
    });
    let results;
    if (options.web) {
      results = options.game?.results;
    } else {
      results = options.ranks?.map((rank) => {
        let obj = options.game?.manual_results?.find(
          (p) => parseInt(p.athlete_id) === parseInt(rank?.athlete_id)
        );

        return { ...obj, rank: rank?.rank };
      });
    }
    let row = 0;
    options?.game?.speed_elimination_stages?.map((stage, stage_index) => {
      stage?.heats?.map((heat, index) => {
        row += 1;
        let athlete_left =
          options?.game?.groups?.[0]?.routes?.[0]?.athletes?.find(
            (a) =>
              parseInt(a.athlete?.ifsc_id) ===
              parseInt(heat?.athletes?.[0]?.athlete_id)
          )?.athlete;
        let athlete_right =
          options?.game?.groups?.[0]?.routes?.[0]?.athletes?.find(
            (a) =>
              parseInt(a.athlete?.ifsc_id) ===
              parseInt(heat?.athletes?.[1]?.athlete_id)
          )?.athlete;

        this.data.push({
          name: "Heat " + row + " Athlete 1 First Name",
          value: {
            text: athlete_left?.first_name || "",
          },
        });
        this.data.push({
          name: "Heat " + row + " Athlete 1 Last Name",
          value: {
            text:
              athlete_left?.last_name || heat?.athletes?.[0]?.lastname || "",
          },
        });

        this.data.push({
          name: "Heat " + row + " Athlete 1 Full Name",
          value: {
            text:
              (athlete_left?.first_name || "") +
              " " +
              (athlete_left?.last_name || ""),
          },
        });
        this.data.push({
          name: "Heat " + row + " Athlete 1  time",
          value: {
            colour:
              heat?.athletes?.[0]?.stage_result?.time <
              heat?.athletes?.[1]?.stage_result?.time
                ? "#D7BC76"
                : "#FFFFFF",
            text:
              heat?.athletes?.[0]?.stage_result?.score === "FALSE START"
                ? "FS"
                : heat?.athletes?.[0]?.stage_result?.time / 1000,
          },
        });

        this.data.push({
          name: "Heat " + row + " Athlete 1 Country",
          value: {
            text:
              athlete_left?.country_of_birth ||
              heat?.athletes?.[0]?.country ||
              "",
          },
        });
        this.data.push({
          name: "Heat " + row + " Athlete 1 Country Flag",
          value: {
            image:
              "Flags/" +
              (athlete_left?.country_of_birth || heat?.athletes?.[0]?.country) +
              ".png",
          },
        });

        this.data.push({
          name: "Heat " + row + " Athlete 2 First Name",
          value: {
            text: athlete_right?.first_name || "",
          },
        });
        this.data.push({
          name: "Heat " + row + " Athlete 2 Last Name",
          value: {
            text:
              athlete_right?.last_name || heat?.athletes?.[1]?.lastname || "",
          },
        });

        this.data.push({
          name: "Heat " + row + " Athlete 2 Full Name",
          value: {
            text:
              (athlete_right?.first_name || "") +
              " " +
              (athlete_right?.last_name || ""),
          },
        });

        this.data.push({
          name: "Heat " + row + " Athlete 2 Country",
          value: {
            text:
              athlete_right?.country_of_birth ||
              heat?.athletes?.[1]?.country ||
              "",
          },
        });
        this.data.push({
          name: "Heat " + row + " Athlete 2 Country Flag",
          value: {
            image:
              "Flags/" +
              (athlete_right?.country_of_birth ||
                heat?.athletes?.[1]?.country) +
              ".png",
          },
        });

        this.data.push({
          name: "Heat " + row + " Athlete 2 time",
          value: {
            colour:
              heat?.athletes?.[1]?.stage_result?.time <
              heat?.athletes?.[0]?.stage_result?.time
                ? "#D7BC76"
                : "#FFFFFF",
            text:
              heat?.athletes?.[1]?.stage_result?.score === "FALSE START"
                ? "FS"
                : heat?.athletes?.[1]?.stage_result?.time / 1000,
          },
        });
        if (row === 15) {
          if (heat?.athletes?.[0]?.stage_result?.winner) {
            this.data.push({
              name: "Podium 3 Athlete First Name",
              value: {
                text: athlete_left?.first_name || "",
              },
            });
            this.data.push({
              name: "Podium 3 Athlete Last Name",
              value: {
                text:
                  athlete_left?.last_name ||
                  heat?.athletes?.[0]?.lastname ||
                  "",
              },
            });

            this.data.push({
              name: "Podium 3 Athlete Full Name",
              value: {
                text:
                  (athlete_left?.first_name || "") +
                  " " +
                  (athlete_left?.last_name || ""),
              },
            });
            this.data.push({
              name: "Podium 3 Athlete time",
              value: {
                text:
                  heat?.athletes?.[0]?.stage_result?.score === "FALSE START"
                    ? "FS"
                    : heat?.athletes?.[0]?.stage_result?.time / 1000,
              },
            });

            this.data.push({
              name: "Podium 3 Athlete Country",
              value: {
                text:
                  athlete_left?.country_of_birth ||
                  heat?.athletes?.[0]?.country ||
                  "",
              },
            });
            this.data.push({
              name: "Podium 3 Athlete Country Flag",
              value: {
                image:
                  "Flags/" +
                  (athlete_left?.country_of_birth ||
                    heat?.athletes?.[0]?.country) +
                  ".png",
              },
            });
          } else {
            this.data.push({
              name: "Podium 3 Athlete First Name",
              value: {
                text: athlete_right?.first_name || "",
              },
            });
            this.data.push({
              name: "Podium 3 Athlete Last Name",
              value: {
                text:
                  athlete_right?.last_name ||
                  heat?.athletes?.[1]?.lastname ||
                  "",
              },
            });

            this.data.push({
              name: "Podium 3 Athlete Full Name",
              value: {
                text:
                  (athlete_right?.first_name || "") +
                  " " +
                  (athlete_right?.last_name || ""),
              },
            });
            this.data.push({
              name: "Podium 3 Athlete time",
              value: {
                text:
                  heat?.athletes?.[1]?.stage_result?.score === "FALSE START"
                    ? "FS"
                    : heat?.athletes?.[1]?.stage_result?.time / 1000,
              },
            });

            this.data.push({
              name: "Podium 3 Athlete Country",
              value: {
                text:
                  athlete_right?.country_of_birth ||
                  heat?.athletes?.[1]?.country ||
                  "",
              },
            });
            this.data.push({
              name: "Podium 3 Athlete Country Flag",
              value: {
                image:
                  "Flags/" +
                  (athlete_right?.country_of_birth ||
                    heat?.athletes?.[1]?.country) +
                  ".png",
              },
            });
          }
        }
        if (row === 16) {
          if (heat?.athletes?.[0]?.stage_result?.winner) {
            this.data.push({
              name: "Podium 1 Athlete First Name",
              value: {
                text: athlete_left?.first_name || "",
              },
            });
            this.data.push({
              name: "Podium 1 Athlete Last Name",
              value: {
                text:
                  athlete_left?.last_name ||
                  heat?.athletes?.[0]?.lastname ||
                  "",
              },
            });

            this.data.push({
              name: "Podium 1 Athlete Full Name",
              value: {
                text:
                  (athlete_left?.first_name || "") +
                  " " +
                  (athlete_left?.last_name || ""),
              },
            });
            this.data.push({
              name: "Podium 1 Athlete time",
              value: {
                text:
                  heat?.athletes?.[0]?.stage_result?.score === "FALSE START"
                    ? "FS"
                    : heat?.athletes?.[0]?.stage_result?.time / 1000,
              },
            });

            this.data.push({
              name: "Podium 1 Athlete Country",
              value: {
                text:
                  athlete_left?.country_of_birth ||
                  heat?.athletes?.[0]?.country ||
                  "",
              },
            });
            this.data.push({
              name: "Podium 1 Athlete Country Flag",
              value: {
                image:
                  "Flags/" +
                  (athlete_left?.country_of_birth ||
                    heat?.athletes?.[0]?.country) +
                  ".png",
              },
            });

            this.data.push({
              name: "Podium 2 Athlete First Name",
              value: {
                text: athlete_right?.first_name || "",
              },
            });
            this.data.push({
              name: "Podium 2 Athlete Last Name",
              value: {
                text:
                  athlete_right?.last_name ||
                  heat?.athletes?.[1]?.lastname ||
                  "",
              },
            });

            this.data.push({
              name: "Podium 2 Athlete Full Name",
              value: {
                text:
                  (athlete_right?.first_name || "") +
                  " " +
                  (athlete_right?.last_name || ""),
              },
            });
            this.data.push({
              name: "Podium 2 Athlete time",
              value: {
                text:
                  heat?.athletes?.[1]?.stage_result?.score === "FALSE START"
                    ? "FS"
                    : heat?.athletes?.[1]?.stage_result?.time / 1000,
              },
            });

            this.data.push({
              name: "Podium 2 Athlete Country",
              value: {
                text:
                  athlete_right?.country_of_birth ||
                  heat?.athletes?.[1]?.country ||
                  "",
              },
            });
            this.data.push({
              name: "Podium 2 Athlete Country Flag",
              value: {
                image:
                  "Flags/" +
                  (athlete_right?.country_of_birth ||
                    heat?.athletes?.[1]?.country) +
                  ".png",
              },
            });
          } else {
            this.data.push({
              name: "Podium 1 Athlete First Name",
              value: {
                text: athlete_right?.first_name || "",
              },
            });
            this.data.push({
              name: "Podium 1 Athlete Last Name",
              value: {
                text:
                  athlete_right?.last_name ||
                  heat?.athletes?.[0]?.lastname ||
                  "",
              },
            });

            this.data.push({
              name: "Podium 1 Athlete Full Name",
              value: {
                text:
                  (athlete_right?.first_name || "") +
                  " " +
                  (athlete_right?.last_name || ""),
              },
            });
            this.data.push({
              name: "Podium 1 Athlete time",
              value: {
                text:
                  heat?.athletes?.[1]?.stage_result?.score === "FALSE START"
                    ? "FS"
                    : heat?.athletes?.[1]?.stage_result?.time / 1000,
              },
            });

            this.data.push({
              name: "Podium 1 Athlete Country",
              value: {
                text:
                  athlete_right?.country_of_birth ||
                  heat?.athletes?.[1]?.country ||
                  "",
              },
            });
            this.data.push({
              name: "Podium 1 Athlete Country Flag",
              value: {
                image:
                  "Flags/" +
                  (athlete_right?.country_of_birth ||
                    heat?.athletes?.[1]?.country) +
                  ".png",
              },
            });

            this.data.push({
              name: "Podium 2 Athlete First Name",
              value: {
                text: athlete_left?.first_name || "",
              },
            });
            this.data.push({
              name: "Podium 2 Athlete Last Name",
              value: {
                text:
                  athlete_left?.last_name ||
                  heat?.athletes?.[0]?.lastname ||
                  "",
              },
            });

            this.data.push({
              name: "Podium 2 Athlete Full Name",
              value: {
                text:
                  (athlete_left?.first_name || "") +
                  " " +
                  (athlete_left?.last_name || ""),
              },
            });
            this.data.push({
              name: "Podium 2 Athlete time",
              value: {
                text:
                  heat?.athletes?.[0]?.stage_result?.score === "FALSE START"
                    ? "FS"
                    : heat?.athletes?.[0]?.stage_result?.time / 1000,
              },
            });

            this.data.push({
              name: "Podium 2 Athlete Country",
              value: {
                text:
                  athlete_left?.country_of_birth ||
                  heat?.athletes?.[0]?.country ||
                  "",
              },
            });
            this.data.push({
              name: "Podium 2 Athlete Country Flag",
              value: {
                image:
                  "Flags/" +
                  (athlete_left?.country_of_birth ||
                    heat?.athletes?.[0]?.country) +
                  ".png",
              },
            });
          }
        }
      });
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
