import React, { useEffect } from "react";
import styled from "styled-components";
import { Button } from "../../Theme/Hyper";

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
const RouteColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1em;
`;

const AscentColumn = styled.div``;

const AthleteRow = styled.div`
  display: flex;
  width: 100%;
  min-height: ${(props) => (!props.noHover ? "39px" : "")};
  padding-right: 0.5em;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  opacity: ${(props) => (props.sub ? "0.5" : "1")};
  background-color: ${(props) =>
    props.fade ? "#181d25" : props.selected ? "#db0a41" : "#202731"};
  border-bottom: 2px solid #181d25;
  cursor: ${(props) => (props.clickable ? "pointer" : "")};
  :hover {
    ${(props) => !props.noHover && !props.selected && { color: "#d11e42" }}
  }
`;
const Name = styled.div`
  min-width: 300px;
`;
const Bib = styled.div`
  min-width: 70px;
  text-align: center;
`;
const Score = styled.div`
  margin-left: 40px;
  min-width: 80px;
`;
const Ascent = styled.div`
  margin-left: 40px;
`;
const Bar = styled.div`
  min-height: 20px;
  height: 20px;
  min-width: 20px;
  border: 1px solid #dfe9e8;
  display: flex;
  flex-flow: column-reverse;
`;
const BarBig = styled.div`
  min-height: 120px;
  height: 120px;
  min-width: 60px;
  border: 1px solid #dfe9e8;
  display: flex;
  flex-flow: column-reverse;
`;
const InnerBar = styled.div`
  display: flex;
  min-height: ${(props) => (props.top ? "100%" : props.zone ? "50%" : "0px")};
  min-width: 100%;
  background-color: #0adb93;
  margin-top: auto;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
`;
const AttemptButton = styled(Button)`
  margin: 5px;
  min-width: 27px;
  justify-content: center;
  background-color: ${(props) => (props.green ? "#0adb93" : "")};
  color: ${(props) => (props.green ? "#000" : "")};
`;
const Input = styled.input`
  background-color: #1f222b;
  border-radius: 4px;
  border: none;
  padding: 1em;
  margin-bottom: 1em;
  outline: none;
  color: #fff;
`;

function Manual({
  selectedPlayer,
  group,
  game,
  setSelectedPlayer,
  updateGame,
  ranks,
  setRanks,
  updateGraphic,
  routeName,
  setRouteName,
}) {
  useEffect(() => {
    let sorted = [...(game?.manual_results || [])]?.sort((a, b) => {
      let a_tops = 0,
        a_top_tries = 0,
        a_zones = 0,
        a_zone_tries = 0,
        b_tops = 0,
        b_top_tries,
        b_zones,
        b_zone_tries;

      a_tops = a?.ascents?.reduce((prev, curr) => {
        return prev + (curr?.top ? 1 : 0);
      }, 0);

      b_tops = b?.ascents?.reduce((prev, curr) => {
        return prev + (curr?.top ? 1 : 0);
      }, 0);

      if (a_tops > b_tops) {
        return -1;
      } else if (a_tops < b_tops) {
        return 1;
      }

      a_top_tries = a?.ascents?.reduce((prev, curr) => {
        return prev + (curr?.top_tries || 0);
      }, 0);

      b_top_tries = b?.ascents?.reduce((prev, curr) => {
        return prev + (curr?.top_tries || 0);
      }, 0);

      if (a_top_tries > b_top_tries) {
        return 1;
      } else if (a_top_tries < b_top_tries) {
        return -1;
      }

      a_zones = a?.ascents?.reduce((prev, curr) => {
        return prev + (curr?.zone ? 1 : 0);
      }, 0);

      b_zones = b?.ascents?.reduce((prev, curr) => {
        return prev + (curr?.zone ? 1 : 0);
      }, 0);

      if (a_zones > b_zones) {
        return -1;
      } else if (a_zones < b_zones) {
        return 1;
      }

      a_zone_tries = a?.ascents?.reduce((prev, curr) => {
        return prev + (curr?.zone_tries || 0);
      }, 0);

      b_zone_tries = b?.ascents?.reduce((prev, curr) => {
        return prev + (curr?.zone_tries || 0);
      }, 0);

      if (a_zone_tries > b_zone_tries) {
        return 1;
      } else if (a_zone_tries < b_zone_tries) {
        return -1;
      }
    });
    sorted = sorted?.map((row, index) => {
      return { ...row, rank: index + 1 };
    });
    setRanks(sorted);
    if (selectedPlayer) {
      updateGraphic("Athlete Active", {
        game: game,
        player: {
          ...selectedPlayer,
          ascents: game?.manual_results?.find(
            (r) => parseInt(r?.athlete_id) === parseInt(selectedPlayer?.ifsc_id)
          )?.ascents,
        },
        rank: sorted?.rank,
        routeName,
      });
    }
  }, [game, selectedPlayer]);

  function getScore({ ascents }) {
    let tops = 0,
      zones = 0,
      top_attempts = 0,
      zone_attempts = 0;
    ascents?.forEach((ascent) => {
      if (ascent.top) {
        tops += 1;
      }
      if (ascent.zone) {
        zones += 1;
      }
      top_attempts += ascent?.top_tries || 0;
      zone_attempts += ascent?.zone_tries || 0;
    });
    return tops + "T" + zones + "z " + top_attempts + " " + zone_attempts;
  }

  return (
    <>
      <RouteColumn>
        <AthleteRow>
          <Bib>ORDER</Bib>
          <Bib>RANK</Bib>
          <Bib>BIB</Bib>
          <Bib>NAT</Bib>
          <Bib>AGE</Bib>
          <Bib>HEIGHT</Bib>
          <Name>NAME</Name>
          <Score>SCORE</Score>
        </AthleteRow>
        {[...(group?.routes?.[0]?.athletes || [])]
          ?.sort((a, b) => a.start_order - b.start_order)
          ?.map((result) => {
            let result_obj = game?.manual_results?.find(
              (r) => r?.athlete_id === result?.athlete?.ifsc_id
            );
            return (
              <AthleteRow
                selected={
                  parseInt(result?.athlete?.ifsc_id) ===
                  parseInt(selectedPlayer?.ifsc_id)
                }
                onClick={() => {
                  if (
                    parseInt(selectedPlayer?.ifsc_id) ===
                    parseInt(result?.athlete?.ifsc_id)
                  ) {
                    setSelectedPlayer();
                  } else {
                    setSelectedPlayer({ ...result?.athlete, bib: result?.bib });
                  }
                }}
              >
                <Bib>{result?.order}</Bib>
                <Bib>
                  {
                    ranks?.find(
                      (s) =>
                        parseInt(s.athlete_id) ===
                        parseInt(result?.athlete?.ifsc_id)
                    )?.rank
                  }
                </Bib>
                <Bib>{result?.bib}</Bib>
                <Bib>{result?.athlete?.country_of_birth}</Bib>
                <Bib>{result?.athlete?.age}</Bib>
                <Bib>{result?.athlete?.height}</Bib>
                <Name>
                  {result?.athlete?.first_name} {result?.athlete?.last_name}
                </Name>
                <Score>{getScore({ ascents: result_obj?.ascents })}</Score>
                {new Array(4).fill(1)?.map((r, index) => {
                  let a = result_obj?.ascents?.[index];

                  return (
                    <Ascent>
                      <Bar>
                        <InnerBar
                          top={a?.top === true}
                          zone={a?.zone === true}
                        ></InnerBar>
                      </Bar>
                    </Ascent>
                  );
                })}
              </AthleteRow>
            );
          })}
      </RouteColumn>
      <RouteColumn>
        {selectedPlayer && (
          <>
            <Row>
              <span>
                {selectedPlayer?.first_name} {selectedPlayer?.last_name}
              </span>
            </Row>
            <Row>
              {new Array(4).fill(1)?.map((a, index) => {
                let result_obj = game?.manual_results?.find(
                  (r) =>
                    parseInt(r?.athlete_id) ===
                    parseInt(selectedPlayer?.ifsc_id)
                );
                let ascent = result_obj?.ascents?.[index];
                return (
                  <RouteColumn>
                    <Row>
                      Route Name{" "}
                      <Input
                        type="text"
                        value={routeName?.[index]}
                        onChange={(e) => {
                          let new_names = [...(routeName || [])];
                          new_names[index] = e.currentTarget.value;
                          setRouteName(new_names);
                        }}
                      />
                    </Row>
                    <AscentColumn>
                      <BarBig
                        onClick={() => {
                          let new_results = [
                            ...(JSON.parse(
                              JSON.stringify(game.manual_results)
                            ) || []),
                          ];
                          let i = new_results?.findIndex(
                            (r) =>
                              parseInt(r?.athlete_id) ===
                              parseInt(selectedPlayer?.ifsc_id)
                          );
                          if (i === -1) {
                            new_results.push({
                              athlete_id: selectedPlayer?.ifsc_id,
                              ascents: [],
                            });

                            i = new_results?.findIndex(
                              (r) =>
                                parseInt(r?.athlete_id) ===
                                parseInt(selectedPlayer?.ifsc_id)
                            );
                          }
                          let ascent = new_results[i]?.ascents?.[index];
                          if (!ascent) {
                            new_results[i].ascents.push({
                              route_name: index + 1,
                            });
                            ascent = new_results[i]?.ascents?.find(
                              (r) => r.route_name === index + 1
                            );
                          }

                          if (ascent.zone && ascent.top) {
                            ascent.top = false;
                            ascent.zone = false;
                          } else if (ascent.zone) {
                            ascent.top = true;
                            ascent.zone = true;
                          } else {
                            ascent.zone = true;
                            ascent.top = false;
                          }

                          updateGame({
                            id: game?._id,
                            manual_results: new_results,
                          });
                        }}
                      >
                        <InnerBar
                          top={ascent?.top === true}
                          zone={ascent?.zone === true}
                        ></InnerBar>
                      </BarBig>
                      <Row>Attempts</Row>
                      <Row>
                        <AttemptButton
                          onClick={() => {
                            let new_results = [
                              ...(JSON.parse(
                                JSON.stringify(game.manual_results)
                              ) || []),
                            ];
                            let i = new_results?.findIndex(
                              (r) =>
                                parseInt(r?.athlete_id) ===
                                parseInt(selectedPlayer?.ifsc_id)
                            );

                            if (i === -1) {
                              new_results.push({
                                athlete_id: selectedPlayer?.ifsc_id,
                                ascents: [],
                              });
                              i = new_results?.findIndex(
                                (r) =>
                                  parseInt(r?.athlete_id) ===
                                  parseInt(selectedPlayer?.ifsc_id)
                              );
                            }

                            let ascent = new_results[i]?.ascents?.[index];
                            if (!ascent) {
                              new_results[i].ascents.push({
                                route_name: index + 1,
                              });
                              ascent = new_results[i]?.ascents?.find(
                                (r) => r.route_name === index + 1
                              );
                            }

                            if (ascent.zone) {
                              ascent.top_tries = Math.max(
                                0,
                                (ascent.top_tries || 0) - 1
                              );
                            } else {
                              ascent.zone_tries = Math.max(
                                0,
                                (ascent.zone_tries || 0) - 1
                              );
                              ascent.top_tries = Math.max(
                                0,
                                (ascent.top_tries || 0) - 1
                              );
                            }

                            updateGame({
                              id: game?._id,
                              manual_results: new_results,
                            });
                          }}
                        >
                          -
                        </AttemptButton>
                        <AttemptButton
                          onClick={() => {
                            let new_results = [
                              ...(JSON.parse(
                                JSON.stringify(game.manual_results)
                              ) || []),
                            ];
                            let i = new_results?.findIndex(
                              (r) =>
                                parseInt(r?.athlete_id) ===
                                parseInt(selectedPlayer?.ifsc_id)
                            );
                            if (i === -1) {
                              new_results.push({
                                athlete_id: selectedPlayer?.ifsc_id,
                                ascents: [],
                              });

                              i = new_results?.findIndex(
                                (r) =>
                                  parseInt(r?.athlete_id) ===
                                  parseInt(selectedPlayer?.ifsc_id)
                              );
                            }

                            let ascent = new_results[i]?.ascents?.[index];
                            if (!ascent) {
                              new_results[i].ascents.push({
                                route_name: index + 1,
                              });
                              ascent = new_results[i]?.ascents?.find(
                                (r) => r.route_name === index + 1
                              );
                            }

                            if (ascent.zone) {
                              ascent.top_tries = (ascent.top_tries || 0) + 1;
                            } else {
                              ascent.zone_tries = (ascent.zone_tries || 0) + 1;
                              ascent.top_tries = (ascent.top_tries || 0) + 1;
                            }

                            updateGame({
                              id: game?._id,
                              manual_results: new_results,
                            });
                          }}
                        >
                          +
                        </AttemptButton>
                      </Row>
                      <Row>Zone: {ascent?.zone_tries}</Row>
                      <Row>Top: {ascent?.top_tries}</Row>
                      <AttemptButton
                        green={ascent?.status === "active"}
                        onClick={() => {
                          let new_results = [
                            ...(JSON.parse(
                              JSON.stringify(game.manual_results)
                            ) || []),
                          ];
                          let i = new_results?.findIndex(
                            (r) =>
                              parseInt(r?.athlete_id) ===
                              parseInt(selectedPlayer?.ifsc_id)
                          );
                          if (i === -1) {
                            new_results.push({
                              athlete_id: selectedPlayer?.ifsc_id,
                              ascents: [],
                            });

                            i = new_results?.findIndex(
                              (r) =>
                                parseInt(r?.athlete_id) ===
                                parseInt(selectedPlayer?.ifsc_id)
                            );
                          }
                          new_results[i].ascents = new_results[i]?.ascents?.map(
                            (a) => {
                              return { ...a, status: "" };
                            }
                          );
                          let ascent = new_results[i]?.ascents?.[index];
                          if (!ascent) {
                            new_results[i].ascents.push({
                              route_name: index + 1,
                            });
                            ascent = new_results[i]?.ascents?.find(
                              (r) => r.route_name === index + 1
                            );
                          }

                          ascent.status = "active";

                          updateGame({
                            id: game?._id,
                            manual_results: new_results,
                          });
                        }}
                      >
                        Active Route
                      </AttemptButton>
                    </AscentColumn>
                  </RouteColumn>
                );
              })}
            </Row>
          </>
        )}
      </RouteColumn>
    </>
  );
}

export default Manual;
