import React, { useEffect } from "react";
import styled from "styled-components";
import { ScrollBar } from "../../Theme/Hyper";
import useLocalStorage from "../../Hooks/useLocalStorage";

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
const RouteColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1em;
`;

const AthleteRow = styled.div`
  display: flex;
  width: 100%;
  min-height: ${(props) => (!props.noHover ? "39px" : "")};
  padding-right: 0.5em;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  opacity: ${(props) => (props.sub ? "0.5" : "1")};
  background-color: ${(props) =>
    props.fade ? "#181d25" : props.selected ? "#db0a41" : "#202731"};
  border-bottom: 2px solid #181d25;
  cursor: ${(props) => (props.clickable ? "pointer" : "")};
  :hover {
    ${(props) => !props.noHover && !props.selected && { color: "#d11e42" }}
  }
`;
const Name = styled.div`
  min-width: 300px;
`;
const Bib = styled.div`
  min-width: 100px;
  text-align: center;
`;
const Score = styled.div`
  margin-left: 40px;
  min-width: 80px;
`;
const Ascent = styled.div`
  margin-left: 40px;
`;
const Bar = styled.div`
  min-height: 20px;
  height: 20px;
  min-width: 20px;
  border: 1px solid #dfe9e8;
  display: flex;
  flex-flow: column-reverse;
`;
const BarBig = styled.div`
  min-height: 120px;
  height: 120px;
  min-width: 60px;
  border: 1px solid #dfe9e8;
  display: flex;
  flex-flow: column-reverse;
`;
const InnerBar = styled.div`
  display: flex;
  min-height: ${(props) => (props.top ? "100%" : props.zone ? "50%" : "0px")};
  min-width: 100%;
  background-color: #0adb93;
  margin-top: auto;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-around;
`;

const AscentColumn = styled.div``;

const Versus = styled.div`
  min-width: 377px;
  text-align: center;
`;

const Scroll = styled(ScrollBar)`
  max-height: 900px;
  overflow-y: auto;
`;
const GraphicButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  height: 4%;
  width: 200px;
  min-height: 40px;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  margin-right: 1em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;

const Input = styled.input`
  background-color: #1f222b;
  border-radius: 4px;
  border: none;
  padding: 1em;
  margin-bottom: 1em;
  outline: none;
  color: #fff;
  width: 50px;
`;

function WebSpeed({
  selectedPlayer,
  group,
  game,
  setSelectedPlayer,
  ranks,
  setRanks,
  previewGraphic,
  triggerAnimation,
}) {
  const [title, setTitle] = useLocalStorage("matchup-title", "");
  const [subtitle, setSubtitle] = useLocalStorage("matchup-subtitle", "");
  useEffect(() => {
    setRanks(game?.results);
  }, [game]);
  return (
    <>
      <RouteColumn>
        {game?.speed_elimination_stages?.map((stage) => {
          return (
            <>
              <span>{stage?.stage_name}</span>
              {stage?.heats?.map((heat) => {
                let athlete_left = heat?.athletes?.[0];
                let athlete_right = heat?.athletes?.[1];
                return (
                  <AthleteRow
                    selected={
                      parseInt(heat?.number) === parseInt(selectedPlayer)
                    }
                    onClick={() => {
                      if (parseInt(selectedPlayer) === parseInt(heat?.number)) {
                        setSelectedPlayer();
                      } else {
                        setSelectedPlayer(heat?.number);
                      }
                    }}
                  >
                    <Name>
                      {athlete_left?.firstname} {athlete_left?.lastname}
                    </Name>
                    <Bib>
                      {athlete_left?.stage_result?.score === "FALSE START"
                        ? "FS"
                        : athlete_left?.stage_result?.time / 1000}
                    </Bib>
                    <Versus>Versus</Versus>
                    <Bib>
                      {athlete_right?.stage_result?.score === "FALSE START"
                        ? "FS"
                        : athlete_right?.stage_result?.time / 1000}
                    </Bib>
                    <Name>
                      {athlete_right?.firstname} {athlete_right?.lastname}
                    </Name>
                  </AthleteRow>
                );
              })}
            </>
          );
        })}

        <Scroll>
          <RouteColumn>
            {group?.name === "Qualification" &&
              game?.groups?.[0]?.routes?.[0]?.athletes?.map(
                (athlete, index) => {
                  let athlete_left = athlete?.athlete;
                  let athlete_right =
                    game?.groups?.[0]?.routes?.[1]?.athletes?.[index]?.athlete;
                  let left_result = game?.results?.find(
                    (a) =>
                      parseInt(a?.athlete_id) ===
                      parseInt(athlete?.athlete?.ifsc_id)
                  );

                  let right_result = game?.results?.find(
                    (a) =>
                      parseInt(a?.athlete_id) ===
                      parseInt(athlete_right?.ifsc_id)
                  );

                  return (
                    <AthleteRow
                      selected={
                        parseInt(index + 1) === parseInt(selectedPlayer)
                      }
                      onClick={() => {
                        if (parseInt(selectedPlayer) === parseInt(index + 1)) {
                          setSelectedPlayer();
                        } else {
                          setSelectedPlayer(index + 1);
                        }
                      }}
                    >
                      <Name>
                        {athlete_left?.first_name} {athlete_left?.last_name}
                      </Name>
                      <Bib>
                        {left_result?.ascents?.find(
                          (a) => a?.route_name === "A"
                        )?.dns
                          ? "DNS"
                          : left_result?.ascents?.find(
                              (a) => a?.route_name === "A"
                            )?.dnf
                          ? "DNF"
                          : (left_result?.ascents?.find(
                              (a) => a?.route_name === "A"
                            )?.time_ms || 0) / 1000}
                      </Bib>
                      <Versus>Versus</Versus>
                      <Bib>
                        {right_result?.ascents?.find(
                          (a) => a?.route_name === "B"
                        )?.dns
                          ? "DNS"
                          : right_result?.ascents?.find(
                              (a) => a?.route_name === "B"
                            )?.dnf
                          ? "DNF"
                          : (right_result?.ascents?.find(
                              (a) => a?.route_name === "B"
                            )?.time_ms || 0) / 1000}
                      </Bib>
                      <Name>
                        {athlete_right?.first_name} {athlete_right?.last_name}
                      </Name>
                    </AthleteRow>
                  );
                }
              )}
          </RouteColumn>
        </Scroll>
      </RouteColumn>
      <RouteColumn>
        <Row>
          Matchup title{" "}
          <Input
            type="text"
            value={title}
            onChange={(e) => {
              setTitle(e.currentTarget.value);
            }}
          />
          Matchup Subtitle{" "}
          <Input
            type="text"
            value={subtitle}
            onChange={(e) => {
              setSubtitle(e.currentTarget.value);
            }}
          />
        </Row>
        {group?.name !== "Qualification" &&
          game?.speed_elimination_stages?.map((stage) => {
            return stage?.heats?.map((heat) => {
              if (heat?.number === selectedPlayer) {
                let athlete_left = group?.routes?.[0]?.athletes?.find((p) => {
                  return (
                    parseInt(p.athlete?.ifsc_id) ===
                    parseInt(heat?.athletes?.[0]?.athlete_id)
                  );
                });

                let athlete_right = group?.routes?.[1]?.athletes?.find((p) => {
                  return (
                    parseInt(p.athlete?.ifsc_id) ===
                    parseInt(heat?.athletes?.[1]?.athlete_id)
                  );
                });

                return (
                  <Row>
                    <GraphicButton
                      onClick={() => {
                        previewGraphic("LTAthlete", {
                          game: game,
                          player: athlete_left?.athlete,
                          title,
                          subtitle,
                        });
                      }}
                    >
                      LT -{" "}
                      {athlete_left?.athlete?.first_name ||
                        heat?.athletes?.[0]?.firstname}{" "}
                      {athlete_left?.athlete?.last_name ||
                        heat?.athletes?.[0]?.lastname}
                    </GraphicButton>
                    <GraphicButton
                      onClick={() => {
                        previewGraphic("LTAthlete", {
                          game: game,
                          player: athlete_right?.athlete,
                          title,
                          subtitle,
                        });
                      }}
                    >
                      LT -{" "}
                      {athlete_right?.athlete?.first_name ||
                        heat?.athletes?.[1]?.firstname}{" "}
                      {athlete_right?.athlete?.last_name ||
                        heat?.athletes?.[1]?.lastname}
                    </GraphicButton>
                  </Row>
                );
              }
            });
          })}

        {group?.name === "Qualification" &&
          game?.groups?.[0]?.routes?.[0]?.athletes?.map((athlete, index) => {
            if (index + 1 === selectedPlayer) {
              let athlete_left = athlete?.athlete;
              let athlete_right =
                game?.groups?.[0]?.routes?.[1]?.athletes?.[index]?.athlete;

              return (
                <Row>
                  <GraphicButton
                    onClick={() => {
                      previewGraphic("LTAthlete", {
                        game: game,
                        player: athlete_left,
                        title,
                        subtitle,
                      });
                    }}
                  >
                    LT - {athlete_left?.first_name} {athlete_left?.last_name}
                  </GraphicButton>
                  <GraphicButton
                    onClick={() => {
                      previewGraphic("LTAthlete", {
                        game: game,
                        player: athlete_right,
                        title,
                        subtitle,
                      });
                    }}
                  >
                    LT - {athlete_right?.first_name} {athlete_right?.last_name}
                  </GraphicButton>
                </Row>
              );
            }
          })}

        {group?.name !== "Qualification" && selectedPlayer && (
          <GraphicButton
            onClick={() => {
              previewGraphic("FFHeadtohead", {
                game: game,
                selectedHeat: selectedPlayer,
                title,
                subtitle,
              });
            }}
          >
            FF Head to Head
          </GraphicButton>
        )}
        {group?.name === "Qualification" && selectedPlayer && (
          <GraphicButton
            onClick={() => {
              previewGraphic("FFHeadtoHeadQual", {
                game: game,
                selectedHeat: selectedPlayer,
                title,
                subtitle,
              });
            }}
          >
            FF Head to Head
          </GraphicButton>
        )}
        {group?.name !== "Qualification" && selectedPlayer && (
          <GraphicButton
            onClick={() => {
              previewGraphic("LTMatchUp", {
                game: game,
                selectedHeat: selectedPlayer,
                title,
                subtitle,
              });
            }}
          >
            LT Matchup
          </GraphicButton>
        )}
        {group?.name === "Qualification" && selectedPlayer && (
          <GraphicButton
            onClick={() => {
              previewGraphic("LTMatchUpQual", {
                game: game,
                selectedHeat: selectedPlayer,
                title,
                subtitle,
              });
            }}
          >
            LT Matchup
          </GraphicButton>
        )}
        <Row>
          {selectedPlayer && (
            <GraphicButton
              onClick={() => {
                triggerAnimation("LTMatchUp", [], "L_WINNER-IN");
                triggerAnimation("LTMatchUp", [], "R_WINNER-OUT");
                triggerAnimation("LTMatchUp", [], "TIMINGS-OUT");
              }}
            >
              Left Winner
            </GraphicButton>
          )}
          {selectedPlayer && (
            <GraphicButton
              onClick={() => {
                triggerAnimation("LTMatchUp", [], "R_WINNER-IN");
                triggerAnimation("LTMatchUp", [], "L_WINNER-OUT");
                triggerAnimation("LTMatchUp", [], "TIMINGS-OUT");
              }}
            >
              Right Winner
            </GraphicButton>
          )}
        </Row>
        <Row>
          {group?.name !== "Qualification" && selectedPlayer && (
            <GraphicButton
              onClick={() => {
                game?.speed_elimination_stages?.map((stage) => {
                  stage?.heats?.map((heat) => {
                    if (heat?.number === selectedPlayer) {
                      let athlete_left = heat?.athletes?.[0];
                      let athlete_right = heat?.athletes?.[1];
                      previewGraphic("LTMatchUp", {
                        data: [
                          {
                            name: "1 Athlete Winner",
                            value: {
                              visible:
                                athlete_left?.stage_result?.time <
                                athlete_right?.stage_result?.time,
                            },
                          },
                          {
                            name: "1 Athlete False Start",
                            value: {
                              visible:
                                athlete_left?.stage_result?.score ===
                                "FALSE START",
                            },
                          },
                          {
                            name: "1 Athlete Time",
                            value: {
                              text:
                                athlete_left?.stage_result?.score ===
                                "FALSE START"
                                  ? ""
                                  : athlete_left?.stage_result?.time / 1000,
                            },
                          },
                          {
                            name: "2 Athlete Winner",
                            value: {
                              visible:
                                athlete_left?.stage_result?.time >
                                athlete_right?.stage_result?.time,
                            },
                          },

                          {
                            name: "2 Athlete False Start",
                            value: {
                              visible:
                                athlete_right?.stage_result?.score ===
                                "FALSE START",
                            },
                          },
                          {
                            name: "2 Athlete Time",
                            value: {
                              text:
                                athlete_right?.stage_result?.score ===
                                "FALSE START"
                                  ? ""
                                  : athlete_right?.stage_result?.time / 1000,
                            },
                          },
                          {
                            name: "1 Athlete Reaction Time",
                            value: {
                              text: "",
                            },
                          },
                          {
                            name: "2 Athlete Reaction Time",
                            value: {
                              text: "",
                            },
                          },
                        ],
                        timeline: "TIMINGS-IN",
                      });
                    }
                  });
                });
              }}
            >
              Preview Times
            </GraphicButton>
          )}
          {group?.name === "Qualification" && selectedPlayer && (
            <GraphicButton
              onClick={() => {
                game?.groups?.[0]?.routes?.[0]?.athletes?.map(
                  (athlete, index) => {
                    if (index + 1 === selectedPlayer) {
                      let athlete_left = athlete?.athlete;
                      let athlete_right =
                        game?.groups?.[0]?.routes?.[1]?.athletes?.[index]
                          ?.athlete;
                      let left_result = game?.results?.find(
                        (a) =>
                          parseInt(a?.athlete_id) ===
                          parseInt(athlete?.athlete?.ifsc_id)
                      );

                      let right_result = game?.results?.find(
                        (a) =>
                          parseInt(a?.athlete_id) ===
                          parseInt(athlete_right?.ifsc_id)
                      );

                      let left_time =
                        (left_result?.ascents?.find(
                          (a) => a?.route_name === "A"
                        )?.time_ms || 0) / 1000;
                      let right_time =
                        (right_result?.ascents?.find(
                          (a) => a?.route_name === "B"
                        )?.time_ms || 0) / 1000;
                      previewGraphic("LTMatchUp", {
                        data: [
                          {
                            name: "1 Athlete Winner",
                            value: {
                              visible: left_time < right_time,
                            },
                          },
                          {
                            name: "1 Athlete False Start",
                            value: {
                              visible: false,
                            },
                          },
                          {
                            name: "1 Athlete Time",
                            value: {
                              text: left_time,
                            },
                          },
                          {
                            name: "2 Athlete Winner",
                            value: {
                              visible: left_time > right_time,
                            },
                          },

                          {
                            name: "2 Athlete False Start",
                            value: {
                              visible: false,
                            },
                          },
                          {
                            name: "2 Athlete Time",
                            value: {
                              text: right_time,
                            },
                          },
                          {
                            name: "1 Athlete Reaction Time",
                            value: {
                              text: "",
                            },
                          },
                          {
                            name: "2 Athlete Reaction Time",
                            value: {
                              text: "",
                            },
                          },
                        ],
                        timeline: "TIMINGS-IN",
                      });
                    }
                  }
                );
              }}
            >
              Preview Times
            </GraphicButton>
          )}
          {group?.name !== "Qualification" && selectedPlayer && (
            <GraphicButton
              onClick={() => {
                game?.speed_elimination_stages?.map((stage) => {
                  stage?.heats?.map((heat) => {
                    if (heat?.number === selectedPlayer) {
                      let athlete_left = heat?.athletes?.[0];
                      let athlete_right = heat?.athletes?.[1];
                      triggerAnimation("LTMatchUp", [], "L_WINNER-OUT");
                      triggerAnimation("LTMatchUp", [], "R_WINNER-OUT");
                      triggerAnimation(
                        "LTMatchUp",
                        [
                          {
                            name: "1 Athlete Winner",
                            value: {
                              visible:
                                athlete_left?.stage_result?.time <
                                athlete_right?.stage_result?.time,
                            },
                          },
                          {
                            name: "1 Athlete False Start",
                            value: {
                              visible:
                                athlete_left?.stage_result?.score ===
                                "FALSE START",
                            },
                          },
                          {
                            name: "1 Athlete Time",
                            value: {
                              text:
                                athlete_left?.stage_result?.score ===
                                "FALSE START"
                                  ? ""
                                  : athlete_left?.stage_result?.time / 1000,
                            },
                          },
                          {
                            name: "2 Athlete Winner",
                            value: {
                              visible:
                                athlete_left?.stage_result?.time >
                                athlete_right?.stage_result?.time,
                            },
                          },

                          {
                            name: "2 Athlete False Start",
                            value: {
                              visible:
                                athlete_right?.stage_result?.score ===
                                "FALSE START",
                            },
                          },
                          {
                            name: "2 Athlete Time",
                            value: {
                              text:
                                athlete_right?.stage_result?.score ===
                                "FALSE START"
                                  ? ""
                                  : athlete_right?.stage_result?.time / 1000,
                            },
                          },
                          {
                            name: "1 Athlete Reaction Time",
                            value: {
                              text: "",
                            },
                          },
                          {
                            name: "2 Athlete Reaction Time",
                            value: {
                              text: "",
                            },
                          },
                        ],
                        "TIMINGS-IN"
                      );
                    }
                  });
                });
              }}
            >
              Show Times
            </GraphicButton>
          )}
          {group?.name === "Qualification" && selectedPlayer && (
            <GraphicButton
              onClick={() => {
                game?.groups?.[0]?.routes?.[0]?.athletes?.map(
                  (athlete, index) => {
                    if (index + 1 === selectedPlayer) {
                      let athlete_left = athlete?.athlete;
                      let athlete_right =
                        game?.groups?.[0]?.routes?.[1]?.athletes?.[index]
                          ?.athlete;
                      let left_result = game?.results?.find(
                        (a) =>
                          parseInt(a?.athlete_id) ===
                          parseInt(athlete?.athlete?.ifsc_id)
                      );

                      let right_result = game?.results?.find(
                        (a) =>
                          parseInt(a?.athlete_id) ===
                          parseInt(athlete_right?.ifsc_id)
                      );

                      let left_time =
                        (left_result?.ascents?.find(
                          (a) => a?.route_name === "A"
                        )?.time_ms || 0) / 1000;
                      let left_dnf = left_result?.ascents?.find(
                        (a) => a?.route_name === "A"
                      )?.dnf;
                      let left_dns = left_result?.ascents?.find(
                        (a) => a?.route_name === "A"
                      )?.dns;
                      let right_time =
                        (right_result?.ascents?.find(
                          (a) => a?.route_name === "B"
                        )?.time_ms || 0) / 1000;
                      let right_dnf = right_result?.ascents?.find(
                        (a) => a?.route_name === "B"
                      )?.dnf;
                      let right_dns = right_result?.ascents?.find(
                        (a) => a?.route_name === "B"
                      )?.dns;
                      triggerAnimation("LTMatchUp", [], "L_WINNER-OUT");
                      triggerAnimation("LTMatchUp", [], "R_WINNER-OUT");
                      triggerAnimation(
                        "LTMatchUp",
                        [
                          {
                            name: "1 Athlete Winner",
                            value: {
                              visible: right_dnf
                                ? true
                                : right_dns
                                ? true
                                : !left_dnf &&
                                  !left_dns &&
                                  left_time < right_time,
                            },
                          },
                          {
                            name: "1 Athlete False Start",
                            value: {
                              visible: false,
                            },
                          },
                          {
                            name: "1 Athlete Time",
                            value: {
                              text: left_dns
                                ? "DNS"
                                : left_dnf
                                ? "DNF"
                                : left_time,
                            },
                          },
                          {
                            name: "2 Athlete Winner",
                            value: {
                              visible: left_dnf
                                ? true
                                : left_dns
                                ? true
                                : !right_dnf &&
                                  !right_dns &&
                                  left_time > right_time,
                            },
                          },

                          {
                            name: "2 Athlete False Start",
                            value: {
                              visible: false,
                            },
                          },
                          {
                            name: "2 Athlete Time",
                            value: {
                              text: right_dns
                                ? "DNS"
                                : right_dnf
                                ? "DNF"
                                : right_time,
                            },
                          },
                          {
                            name: "1 Athlete Reaction Time",
                            value: {
                              text: "",
                            },
                          },
                          {
                            name: "2 Athlete Reaction Time",
                            value: {
                              text: "",
                            },
                          },
                        ],
                        "TIMINGS-IN"
                      );
                    }
                  }
                );
              }}
            >
              Show Times
            </GraphicButton>
          )}
        </Row>
      </RouteColumn>
    </>
  );
}

export default WebSpeed;
