import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useLocalStorage from "../../Hooks/useLocalStorage";

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
const RouteColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1em;
`;

const AthleteRow = styled.div`
  display: flex;
  width: 100%;
  min-height: ${(props) => (!props.noHover ? "39px" : "")};
  padding-right: 0.5em;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  opacity: ${(props) => (props.sub ? "0.5" : "1")};
  background-color: ${(props) =>
    props.fade ? "#181d25" : props.selected ? "#db0a41" : "#202731"};
  border-bottom: 2px solid #181d25;
  cursor: ${(props) => (props.clickable ? "pointer" : "")};
  :hover {
    ${(props) => !props.noHover && !props.selected && { color: "#d11e42" }}
  }
`;
const Name = styled.div`
  min-width: 300px;
`;
const Bib = styled.div`
  min-width: 70px;
  text-align: center;
`;
const Score = styled.div`
  margin-left: 40px;
  min-width: 80px;
`;
const Ascent = styled.div`
  margin-left: 40px;
`;
const Bar = styled.div`
  min-height: 20px;
  height: 20px;
  min-width: 20px;
  border: 1px solid #dfe9e8;
  display: flex;
  flex-flow: column-reverse;
`;
const BarBig = styled.div`
  min-height: 120px;
  height: 120px;
  min-width: 60px;
  border: 1px solid #dfe9e8;
  display: flex;
  flex-flow: column-reverse;
`;
const InnerBar = styled.div`
  display: flex;
  min-height: ${(props) => (props.top ? "100%" : props.zone ? "50%" : "0px")};
  min-width: 100%;
  background-color: #0adb93;
  margin-top: auto;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
`;

const AscentColumn = styled.div``;

const Input = styled.input`
  background-color: #1f222b;
  border-radius: 4px;
  border: none;
  padding: 1em;
  margin-bottom: 1em;
  outline: none;
  color: #fff;
  width: 50px;
`;
function Web({
  selectedPlayer,
  group,
  game,
  setSelectedPlayer,
  ranks,
  setRanks,
  updateGraphic,
  routeName,
  setRouteName,
}) {
  useEffect(() => {
    setRanks(game?.results);
    if (selectedPlayer) {
      updateGraphic("Athlete Active", {
        game: game,
        player: {
          ...selectedPlayer,
          ascents: game?.results?.find(
            (r) => parseInt(r?.athlete_id) === parseInt(selectedPlayer?.ifsc_id)
          )?.ascents,
        },
        rank: game?.results?.find(
          (s) => parseInt(s.athlete_id) === parseInt(selectedPlayer?.ifsc_id)
        )?.rank,
        routeName,
      });
    }
  }, [game, selectedPlayer]);
  return (
    <>
      <RouteColumn>
        <AthleteRow>
          <Bib>ORDER</Bib>
          <Bib>RANK</Bib>
          <Bib>BIB</Bib>
          <Bib>NAT</Bib>
          <Bib>AGE</Bib>
          <Bib>HEIGHT</Bib>
          <Name>NAME</Name>
          <Score>SCORE</Score>
        </AthleteRow>
        {[...(group?.routes?.[0]?.athletes || [])]
          ?.sort((a, b) => a.order - b.order)
          ?.map((result) => {
            let result_obj = game?.results?.find(
              (r) => r?.athlete_id === parseInt(result?.athlete?.ifsc_id)
            );
            return (
              <AthleteRow
                selected={
                  parseInt(result?.athlete?.ifsc_id) ===
                  parseInt(selectedPlayer?.ifsc_id)
                }
                onClick={() => {
                  if (
                    parseInt(selectedPlayer?.ifsc_id) ===
                    parseInt(result?.athlete?.ifsc_id)
                  ) {
                    setSelectedPlayer();
                  } else {
                    setSelectedPlayer({ ...result?.athlete, bib: result?.bib });
                  }
                }}
              >
                <Bib>{result?.order}</Bib>
                <Bib>{result_obj?.rank}</Bib>
                <Bib>{result?.bib}</Bib>
                <Bib>{result?.athlete?.country_of_birth}</Bib>
                <Bib>{result?.athlete?.age}</Bib>
                <Bib>{result?.athlete?.height}</Bib>
                <Name>
                  {result?.athlete?.first_name} {result?.athlete?.last_name}
                </Name>
                <Score>{result_obj?.score}</Score>
                {result_obj?.ascents?.map((a) => {
                  return (
                    <Ascent>
                      <Bar>
                        <InnerBar
                          top={a?.top === true}
                          zone={a?.zone === true}
                        ></InnerBar>
                      </Bar>
                    </Ascent>
                  );
                })}
              </AthleteRow>
            );
          })}
      </RouteColumn>
      <RouteColumn>
        {selectedPlayer && (
          <>
            <Row>
              <span>
                {selectedPlayer?.first_name} {selectedPlayer?.last_name}
              </span>
            </Row>
            <Row>
              {game?.results
                ?.find(
                  (r) => r?.athlete_id === parseInt(selectedPlayer?.ifsc_id)
                )
                ?.ascents?.map((a, index) => {
                  return (
                    <RouteColumn>
                      <Row>
                        Route Name{" "}
                        <Input
                          type="text"
                          value={routeName?.[index]}
                          onChange={(e) => {
                            let new_names = [...(routeName || [])];
                            new_names[index] = e.currentTarget.value;
                            setRouteName(new_names);
                          }}
                        />
                      </Row>

                      <AscentColumn>
                        <BarBig>
                          <InnerBar
                            top={a?.top === true}
                            zone={a?.zone === true}
                          ></InnerBar>
                        </BarBig>
                      </AscentColumn>
                      <Row>Attempts</Row>
                      <Row>Zone: {a?.zone_tries}</Row>
                      <Row>Top: {a?.top_tries}</Row>
                      <Row>{a?.status}</Row>
                    </RouteColumn>
                  );
                })}
            </Row>
          </>
        )}
      </RouteColumn>
    </>
  );
}

export default Web;
