export class AthleteProfile {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Athlete Profile";
    this.scene = "Athlete Profile";
    this.data = [];
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  preview({ options }) {
    this.onAir = false;

    this.data = [];
    this.data.push({
      name: "Competition Title",
      value: {
        text: options?.game?.competition?.name || "",
      },
    });
    this.data.push({
      name: "Category Title",
      value: {
        text: options?.game?.description?.split(" - ")?.[0] || "",
      },
    });

    this.data.push({
      name: "Category subtitle",
      value: {
        text: options?.game?.description?.split(" - ")?.[1] || "",
      },
    });

    this.data.push({
      name: "Athlete Headshot",
      value: {
        image:
          "MEN_HS/" +
          options.player.first_name +
          "_" +
          this.toTitleCase(options.player.last_name) +
          ".png",
      },
    });

    this.data.push({
      name: "Athlete First Name",
      value: {
        text: options.player.first_name || "",
      },
    });
    this.data.push({
      name: "Athlete Last Name",
      value: {
        text: options.player.last_name || "",
      },
    });
    this.data.push({
      name: "Athlete Full Name",
      value: {
        text:
          (options.player.first_name || "") +
          " " +
          (options.player.last_name || ""),
      },
    });
    this.data.push({
      name: "Athlete Bib",
      value: {
        text: options.player.bib || "",
      },
    });
    this.data.push({
      name: "Athlete Country",
      value: {
        text: options.player.country_of_birth || "",
      },
    });
    this.data.push({
      name: "Athlete Country Flag",
      value: {
        image: "Flags/" + options.player.country_of_birth + ".png",
      },
    });
    this.data.push({
      name: "Athlete Age",
      value: {
        text: options.player.age || "",
      },
    });
    this.data.push({
      name: "Athlete Height",
      value: {
        text: options.player.height ? options.player.height + "cm" : "",
      },
    });
    this.data.push({
      name: "Athlete Position",
      value: {
        text: options.player.rank || "",
      },
    });
    this.data.push({
      name: "Top",
      value: {
        text: options.player.ascents?.filter((a) => a.top)?.length || "0",
      },
    });
    this.data.push({
      name: "Zone",
      value: {
        text: options.player.ascents?.filter((a) => a.zone)?.length || "0",
      },
    });
    this.data.push({
      name: "Top Attempts",
      value: {
        text: options.player.ascents?.reduce((prev, curr) => {
          return prev + curr.top_tries;
        }, 0),
      },
    });
    this.data.push({
      name: "Zone Attempts",
      value: {
        text: options.player.ascents?.reduce((prev, curr) => {
          return prev + curr.zone_tries;
        }, 0),
      },
    });

    this.data.push({
      name: "Participations",
      value: {
        text: options.player.participations,
      },
    });

    this.data.push({
      name: "Tops",
      value: {
        text: options.player.tops_stat ? options.player.tops_stat + "%" : "0%",
      },
    });

    this.data.push({
      name: "Gold",
      value: {
        text: options.player.podiums?.find(
          (p) => p.discipline_kind === "boulder"
        )?.["1"],
      },
    });

    this.data.push({
      name: "Silver",
      value: {
        text: options.player.podiums?.find(
          (p) => p.discipline_kind === "boulder"
        )?.["2"],
      },
    });

    this.data.push({
      name: "Bronze",
      value: {
        text: options.player.podiums?.find(
          (p) => p.discipline_kind === "boulder"
        )?.["3"],
      },
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
