export class FFMatchUp {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "FFMatchUp";
    this.scene = "FFMatchUp";
    this.data = [];
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];
    this.data.push({
      name: "Competition Title",
      value: {
        text: options?.game?.competition?.name || "",
      },
    });

    this.data.push({
      name: "Category Title",
      value: {
        text: options?.game?.description?.split(" - ")?.[0] || "",
      },
    });

    this.data.push({
      name: "Category subtitle",
      value: {
        text: options?.game?.description?.split(" - ")?.[1] || "",
      },
    });

    [...(options.game?.results || [])]
      ?.sort((a, b) => a.rank - b.rank)
      ?.map((result, index) => {
        let athlete = options?.game?.groups?.[0]?.routes?.[0]?.athletes?.find(
          (a) => parseInt(a?.athlete?.ifsc_id) === parseInt(result?.athlete_id)
        );
        let row = index + 1;

        this.data.push({
          name: row + " Athlete Headshot",
          value: {
            image:
              "MEN_HS/" +
              athlete?.athlete?.first_name +
              "_" +
              this.toTitleCase(athlete?.athlete?.last_name) +
              ".png",
          },
        });

        this.data.push({
          name: row + " Athlete First Name",
          value: {
            text: athlete?.athlete?.first_name || "",
          },
        });
        this.data.push({
          name: row + " Athlete Last Name",
          value: {
            text: athlete?.athlete?.last_name || "",
          },
        });
        this.data.push({
          name: row + " Athlete Full Name",
          value: {
            text:
              (athlete?.athlete?.first_name || "") +
              " " +
              (athlete?.athlete?.last_name || ""),
          },
        });
        this.data.push({
          name: row + " Athlete Country",
          value: {
            text: athlete?.athlete?.country_of_birth || "",
          },
        });
        this.data.push({
          name: row + " Athlete Country Flag",
          value: {
            image: "Flags/" + athlete?.athlete?.country_of_birth + ".png",
          },
        });

        this.data.push({
          name: row + " Athlete Bib",
          value: {
            text: athlete?.bib || "",
          },
        });
      });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
