export class FFStartList {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "StartList";
    this.scene = "FFStartList";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];
    this.data.push({
      name: "Competition Title",
      value: {
        text: options?.game?.competition?.name || "",
      },
    });

    this.data.push({
      name: "Category Title",
      value: {
        text:
          options?.game?.description
            ?.split(" - ")?.[0]
            ?.replace("BOULDER Women", "WOMEN'S BOULDER")
            ?.replace("BOULDER Men", "MEN'S BOULDER") || "",
      },
    });

    this.data.push({
      name: "Category subtitle",
      value: {
        text: options?.game?.description?.split(" - ")?.[1] || "",
      },
    });

    let results = [
      ...(options?.game?.groups?.[0]?.routes?.[0]?.athletes || []),
    ]?.sort((a, b) => a.order - b.order);
    new Array(10).fill(1)?.map((_, index) => {
      let result = results[index];
      let athlete = result?.athlete;

      let row = index + 1;
      this.data.push({
        name: row + " Athlete First Name",
        value: {
          text: athlete?.first_name || "",
        },
      });
      this.data.push({
        name: row + " Athlete Last Name",
        value: {
          text: athlete?.last_name || "",
        },
      });
      this.data.push({
        name: row + " Athlete Full Name",
        value: {
          text: (athlete?.first_name || "") + " " + (athlete?.last_name || ""),
        },
      });
      this.data.push({
        name: row + " Athlete Country",
        value: {
          text: athlete?.country_of_birth || "",
        },
      });

      this.data.push({
        name: row + " Athlete Country Flag",
        value: {
          image: "Flags/" + athlete?.country_of_birth + ".png",
        },
      });

      this.data.push({
        name: row + " Athlete Position",
        value: {
          text: result?.order || "",
        },
      });
      this.data.push({
        name: row + " Athlete Bib",
        value: {
          text: result?.bib || "",
        },
      });
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
