import React, { useContext, useEffect, useState } from "react";
import Game from "../Game/Game";
import RightSideBar from "../RightSideBar/RightSideBar";
import styled from "styled-components";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import TeamNames from "../TopBar/TeamNames";
import Goal from "../TopBar/Goal";
import { UIContext } from "../../contexts/UIContext";
import ClockEdit from "../ClockEdit/ClockEdit";
import { Route, Switch, useParams } from "react-router";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import Goals from "../Goals/Goals";
import Penalties from "../Penalties/Penalties";
import { ControlContext } from "../../contexts/ControlContext";
import { APIContext } from "../../contexts/APIContext";
import { ClockContext } from "../../contexts/ClockContext";
import useLocalStorage from "../../Hooks/useLocalStorage";

const GAME_BY_ID = gql`
  query GameByID($id: String) {
    gameById(id: $id) {
      _id
      time
      clock
      description
      date
      competition {
        name
        badge
        competition_type
      }
      live_data
      config
      results
      manual_results
      round_type
      speed_elimination_stages
      groups {
        name
        routes {
          ifsc_id
          name
          athletes {
            bib
            order
            athlete {
              ifsc_id
              first_name
              last_name
              country_of_birth
              age
              height
              podiums
              participations
              tops_stat
              speed_personal_best
              speed_seasonal_best
              progression_rates
            }
          }
        }
      }
    }
  }
`;

const GAME_SUBSCRIPTION = gql`
  subscription GameUpdated($id: String!) {
    gameUpdated(_id: $id) {
      _id
      live_data
      results
      description
      manual_results
      speed_elimination_stages
      groups {
        name
        routes {
          ifsc_id
          name
          athletes {
            bib
            order
            athlete {
              ifsc_id
              first_name
              last_name
              country_of_birth
              age
              height
              podiums
              participations
              tops_stat
              speed_personal_best
              speed_seasonal_best
              progression_rates
            }
          }
        }
      }
    }
  }
`;

const Main = styled.div`
  display: flex;
  width: 100%;
`;
const Content = styled.div`
  display: flex;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 1em;
  box-sizing: border-box;
  flex: 1;
  flex-direction: column;
`;
const RightBar = styled.div`
  display: flex;
  width: 300px;
  box-shadow: -3px 1px 3px 0 rgb(0 0 0 / 42%);
`;
const LeftBar = styled.div`
  display: flex;
  width: 44px;
  box-shadow: 3px -1px 3px 0 rgb(0 0 0 / 42%);
  background-color: #202731;
`;
export default function Live({ setSelectedPlayer, selectedPlayer }) {
  const { getGroupsBySeason } = useContext(APIContext);
  let { gameID } = useParams();
  const [game, setGame] = useState();
  const [group, setGroup] = useState();
  const [groups, setGroups] = useState();
  const [homeManager, setHomeManager] = useState();
  const [awayManager, setAwayManager] = useState();
  const [ranks, setRanks] = useState();

  const [view, setView] = useLocalStorage("scoring_view", 0);

  const [getGame, { data, subscribeToMore }] = useLazyQuery(GAME_BY_ID, {
    fetchPolicy: "network-first",
  });

  useEffect(() => {
    if (gameID) {
      getGame({ variables: { id: gameID } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameID]);

  useEffect(() => {
    let unsubscribe;
    if (subscribeToMore) {
      console.log("Subscribed");
      unsubscribe = subscribeToMore({
        document: GAME_SUBSCRIPTION,
        variables: { id: gameID },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;

          const updatedGame = subscriptionData.data.gameUpdated;

          let newGame = {
            gameById: { ...prev.gameById, ...updatedGame },
          };

          return newGame;
        },
      });
    }
    return () => {
      console.log("Unsubscribed");
      unsubscribe && unsubscribe();
    };
  }, [subscribeToMore, gameID]);

  useEffect(() => {
    if (data) {
      setGame(data.gameById);
    }
  }, [data]);

  const { goal, setGoal } = useContext(UIContext);
  const {
    previewGraphic,
    animateGraphic,
    graphicInPreview,
    animateOffGraphic,
    updateGraphic,
    clearPVW,
    triggerAnimation,
  } = useContext(GraphicsContext);
  const { updateGame } = useContext(APIContext);
  const [routeName, setRouteName] = useLocalStorage("route_name", []);
  useEffect(() => {
    getGroupsBySeason({ seasonId: game?.season?._id })?.then((data) => {
      let g = data?.find(
        (g) =>
          g.teams?.findIndex((t) => t?.team?._id === game?.home_team?._id) > -1
      );

      setGroups(data);
      setGroup(g);
    });
  }, [game]);

  if (!game) return <div>Loading</div>;
  return (
    game && (
      <Main>
        <Content>
          <Switch>
            <Route path="/:gameID/penalties">
              <Penalties game={game} />
            </Route>
            <Route path="/:gameID/clock">
              <ClockEdit game={game} group={group} />
            </Route>
            <Route path="/:gameID/goals">
              <Goals
                game={game}
                homeLineup={game?.lineup?.home}
                awayLineup={game?.lineup?.away}
                setGoal={setGoal}
              />
            </Route>
            =
            <Route path="/:gameID">
              <Game
                setHomeManager={setHomeManager}
                setAwayManager={setAwayManager}
                setGoal={setGoal}
                goal={goal}
                game={game}
                setSelectedPlayer={setSelectedPlayer}
                selectedPlayer={selectedPlayer}
                view={view}
                setView={setView}
                ranks={ranks}
                setRanks={setRanks}
                setGroup={setGroup}
                group={group}
                previewGraphic={previewGraphic}
                triggerAnimation={triggerAnimation}
                updateGraphic={updateGraphic}
                setRouteName={setRouteName}
                routeName={routeName}
              />
            </Route>
          </Switch>
        </Content>

        <RightBar>
          <RightSideBar
            group={group}
            groups={groups}
            game={game}
            homeManager={homeManager}
            awayManager={awayManager}
            previewGraphic={previewGraphic}
            graphicInPreview={graphicInPreview}
            animateGraphic={animateGraphic}
            animateOffGraphic={animateOffGraphic}
            updateGraphic={updateGraphic}
            selectedPlayer={selectedPlayer}
            clearPreview={clearPVW}
            view={view}
            ranks={ranks}
            routeName={routeName}
          />
        </RightBar>
      </Main>
    )
  );
}
