import { Clock } from "./Clock";
import { AthleteActive } from "./AthleteActive";
import { AthleteIntro } from "./AthleteIntro";
import { AthleteProfile } from "./AthleteProfile";
import { FFTable } from "./FFTable";
import { Winner } from "./Winner";
import { FFMatchUp } from "./FFMatchUp";
import { FFBracket } from "./FFBracket";
import { LTMatchUp } from "./LTMatchUp";
import { SpeedFFTable } from "./SpeedFFTable";
import { FFHeadtohead } from "./FFHeadtoHead";
import { LTAthlete } from "./LTAthlete";
import { FFStartList } from "./FFStartList";
import { FFHeadtoHeadQual, FFHeadtoheadQual } from "./FFHeadtoHeadQual";
import { LTMatchUpQual } from "./LTMatchUpQual";

export default function graphics({
  preview,
  animate,
  update,
  animateOff,
  triggerAnimation,
  clearPreview,
}) {
  return [
    new FFTable({ preview, animate, update, animateOff, triggerAnimation }),
    new AthleteIntro({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
    }),
    new AthleteActive({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
    }),
    new AthleteProfile({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
    }),
    new Clock({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
    }),
    new Winner({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
    }),
    new FFMatchUp({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
    }),
    new FFBracket({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
    }),
    new LTMatchUp({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
    }),
    new SpeedFFTable({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
    }),
    new FFHeadtohead({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
    }),
    new LTAthlete({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
    }),
    new FFStartList({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
    }),
    new FFHeadtoHeadQual({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
    }),
    new LTMatchUpQual({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
    }),
  ];
}
