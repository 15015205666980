export class LTMatchUp {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "LTMatchUp";
    this.scene = "LTMatchUp";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];
    if (options.timeline) {
      this.sendPreview(this.scene, 500, options.data, options.timeline);
    } else {
      this.data.push({
        name: "Competition Title",
        value: {
          text: options?.game?.competition?.name || "",
        },
      });

      this.data.push({
        name: "Category Title",
        value: {
          text: options?.title,
        },
      });

      this.data.push({
        name: "Category subtitle",
        value: {
          text: options?.subtitle,
        },
      });

      options.game?.speed_elimination_stages?.map((stage) => {
        stage?.heats?.map((heat) => {
          if (heat?.number === options.selectedHeat) {
            let athlete_left =
              options?.game?.groups?.[0]?.routes?.[0]?.athletes?.find(
                (a) =>
                  parseInt(a.athlete?.ifsc_id) ===
                  parseInt(heat?.athletes?.[0]?.athlete_id)
              )?.athlete;
            let athlete_right =
              options?.game?.groups?.[0]?.routes?.[1]?.athletes?.find(
                (a) =>
                  parseInt(a.athlete?.ifsc_id) ===
                  parseInt(heat?.athletes?.[1]?.athlete_id)
              )?.athlete;

            this.data.push({
              name: "1 Athlete First Name",
              value: {
                text:
                  athlete_left?.first_name ||
                  heat?.athletes?.[0]?.firstname ||
                  "",
              },
            });
            this.data.push({
              name: "1 Athlete Last Name",
              value: {
                text:
                  athlete_left?.last_name ||
                  heat?.athletes?.[0]?.lastname ||
                  "",
              },
            });

            this.data.push({
              name: "1 Athlete Full Name",
              value: {
                text:
                  (athlete_left?.first_name ||
                    heat?.athletes?.[0]?.firstname ||
                    "") +
                  " " +
                  (athlete_left?.last_name ||
                    heat?.athletes?.[0]?.lastname ||
                    ""),
              },
            });

            this.data.push({
              name: "1 Athlete Country",
              value: {
                text:
                  athlete_left?.country_of_birth ||
                  heat?.athletes?.[0]?.country ||
                  "",
              },
            });
            this.data.push({
              name: "1 Athlete Country Flag",
              value: {
                image: "Flags/" + athlete_left?.country_of_birth + ".png",
              },
            });

            this.data.push({
              name: "1 Athlete PB",
              value: {
                text: athlete_left?.speed_personal_best?.time,
              },
            });

            this.data.push({
              name: "2 Athlete First Name",
              value: {
                text:
                  athlete_right?.first_name ||
                  heat?.athletes?.[1]?.firstname ||
                  "",
              },
            });
            this.data.push({
              name: "2 Athlete Last Name",
              value: {
                text:
                  athlete_right?.last_name ||
                  heat?.athletes?.[1]?.lastname ||
                  "",
              },
            });

            this.data.push({
              name: "2 Athlete Full Name",
              value: {
                text:
                  (athlete_right?.first_name ||
                    heat?.athletes?.[1]?.firstname ||
                    "") +
                  " " +
                  (athlete_right?.last_name ||
                    heat?.athletes?.[1]?.lastname ||
                    ""),
              },
            });

            this.data.push({
              name: "2 Athlete Country",
              value: {
                text:
                  athlete_right?.country_of_birth ||
                  heat?.athletes?.[1]?.country ||
                  "",
              },
            });
            this.data.push({
              name: "2 Athlete Country Flag",
              value: {
                image: "Flags/" + athlete_right?.country_of_birth + ".png",
              },
            });

            this.data.push({
              name: "2 Athlete PB",
              value: {
                text: athlete_right?.speed_personal_best?.time,
              },
            });
          }
        });
      });

      this.sendPreview(this.scene, 500, this.data, "IN");
    }
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
