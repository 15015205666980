import React, { useContext, useEffect, useState } from "react";
import HyperLogo from "../../Images/HyperLogo.png";
import styled from "styled-components";
import Clock from "./Clock";
import Player from "./Player";
import Subs from "../Subs/Subs";
import EndHalf from "./EndHalf";
import moment from "moment";
import { Button, ScrollBar } from "../../Theme/Hyper";
import { UserContext } from "../../contexts/UserContext";
import Select from "react-select";
import { ControlContext } from "../../contexts/ControlContext";
import { APIContext } from "../../contexts/APIContext";

const Main = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 0.5em;
  background-color: #202731;
  box-sizing: border-box;
  flex-direction: column;
  position: relative;
`;
const Logo = styled.img`
  height: auto;
  width: 50%;
`;
const GraphicButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  height: 4%;
  width: 90%;
  min-height: 40px;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;
const CurrentGraphic = styled.div`
  margin-top: auto;
  width: 100%;
  height: 167px;
  background-color: #181d25;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 0.5em;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 102px;
  margin: 0px;
  padding-bottom: 10px;
`;
const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
const NavButton = styled(Button)`
  margin: 0.5em;
  background-color: ${(props) => (props.selected ? "#0adb93" : "")};
  color: ${(props) => (props.selected ? "#1d1d1d" : "")};
`;

const Profile = styled.div`
  width: 100%;
  height: 35px;
  background-color: #202731;
  z-index: 1000;
  position: -webkit-sticky;
  position: sticky;
  box-shadow: 0px 4px 12px -20px rgb(0 0 0 / 20%),
    0px 8px 20px 2px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
  display: flex;
  align-items: center;
  border-bottom: 1px solid #030304;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
  padding-bottom: 5px;
  margin-bottom: 10px;
`;

const SelectContainer = styled(Select)`
  min-width: 200px;
  margin-bottom: 0.5em;
  width: 90%;
`;

const GraphicsList = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  max-height: 560px;
  overflow-y: auto;
  width: 100%;
  align-items: center;
`;

const DropdownStyles = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#0e1219",
  }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: "#0e1219",
      "&:hover": {
        color: "#db0a41",
      },
    };
  },
  control: (provided) => ({
    ...provided,
    backgroundColor: "#0e1219",
    borderColor: "#384454",
  }),
  valueContainer: (provided) => ({
    ...provided,
    backgroundColor: "#0e1219",
    borderColor: "#384454",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition, color: "#fff" };
  },
};

export default function RightSideBar({
  game,
  updateGame,
  previewGraphic,
  graphicInPreview,
  animateGraphic,
  animateOffGraphic,
  updateGraphic,
  teams,
  selectedPlayer,
  players,
  stadiums,
  sky,
  squads,
  countries,
  standings,
  kits,
  clearPreview,
  homeManager,
  awayManager,
  groups,
  group,
  view: scoring_view,
  ranks,
  routeName,
}) {
  const { user, signOut } = useContext(UserContext);
  const { engines, selectedEngine, setSelectedEngine } =
    useContext(ControlContext);
  const { getGroup, getFixtures } = useContext(APIContext);
  const [view, setView] = useState(0);
  let period = [...(game?.live_data?.periods || [])].sort(
    (a, b) => b.number - a.number
  )[0];

  let latestGoal =
    game && game.live_data?.home_scorers ? game.live_data?.home_scorers : [];

  latestGoal = latestGoal
    .concat(game.live_data?.away_scorers || [])
    .sort((a, b) => b.time - a.time);
  if (latestGoal.length > 0) {
    latestGoal = latestGoal[0].time;
  }
  let time = moment.duration(latestGoal);
  time =
    time.minutes() +
    time.hours() * 60 +
    ":" +
    time.seconds().toString().padStart(2, "0");

  return (
    <Main>
      {!window.hideHeader && (
        <Profile>
          {user?.username}
          <Button
            style={{ border: "1px solid #fff", marginLeft: "auto" }}
            onClick={() => {
              signOut();
            }}
          >
            Sign Out
          </Button>
        </Profile>
      )}
      {engines?.length > 1 && (
        <SelectContainer
          styles={DropdownStyles}
          setSize={true}
          value={{
            value: selectedEngine,
            label: engines?.find((e) => e.key === selectedEngine)?.name,
          }}
          options={engines.map((item, index) => {
            return {
              value: item.key,
              label: item.name,
            };
          })}
          onChange={(e) => {
            setSelectedEngine(e.value);
          }}
          placeholder="Round"
        />
      )}
      <Logo src={HyperLogo}></Logo>
      <Clock
        game={game}
        teams={teams}
        updateGame={updateGame}
        previewGraphic={previewGraphic}
        animateGraphic={animateGraphic}
        animateOffGraphic={animateOffGraphic}
        updateGraphic={updateGraphic}
        sky={sky}
        group={group}
      />
      <GraphicsList>
        {game?.round_type === "boulder" && selectedPlayer && (
          <GraphicButton
            onClick={() => {
              previewGraphic("Athlete Intro", {
                game: game,
                player: {
                  ...selectedPlayer,
                  ascents:
                    scoring_view === 0
                      ? game?.results?.find(
                          (r) =>
                            parseInt(r?.athlete_id) ===
                            parseInt(selectedPlayer?.ifsc_id)
                        )?.ascents
                      : game?.manual_results?.find(
                          (r) =>
                            parseInt(r?.athlete_id) ===
                            parseInt(selectedPlayer?.ifsc_id)
                        )?.ascents,
                  rank: ranks?.find(
                    (s) =>
                      parseInt(s.athlete_id) ===
                      parseInt(selectedPlayer?.ifsc_id)
                  )?.rank,
                },
              });
            }}
          >
            Athlete Intro
          </GraphicButton>
        )}
        {game?.round_type === "boulder" && selectedPlayer && (
          <GraphicButton
            onClick={() => {
              previewGraphic("Athlete Active", {
                game: game,
                player: {
                  ...selectedPlayer,
                  ascents:
                    scoring_view === 0
                      ? game?.results?.find(
                          (r) =>
                            parseInt(r?.athlete_id) ===
                            parseInt(selectedPlayer?.ifsc_id)
                        )?.ascents
                      : game?.manual_results?.find(
                          (r) =>
                            parseInt(r?.athlete_id) ===
                            parseInt(selectedPlayer?.ifsc_id)
                        )?.ascents,
                  rank: ranks?.find(
                    (s) =>
                      parseInt(s.athlete_id) ===
                      parseInt(selectedPlayer?.ifsc_id)
                  )?.rank,
                  routeName,
                },
              });
            }}
          >
            Athlete Active
          </GraphicButton>
        )}
        {game?.round_type === "boulder" && selectedPlayer && (
          <GraphicButton
            onClick={() => {
              previewGraphic("Athlete Profile", {
                game: game,
                player: {
                  ...selectedPlayer,
                  ascents:
                    scoring_view === 0
                      ? game?.results?.find(
                          (r) =>
                            parseInt(r?.athlete_id) ===
                            parseInt(selectedPlayer?.ifsc_id)
                        )?.ascents
                      : game?.manual_results?.find(
                          (r) =>
                            parseInt(r?.athlete_id) ===
                            parseInt(selectedPlayer?.ifsc_id)
                        )?.ascents,
                  rank: ranks?.find(
                    (s) =>
                      parseInt(s.athlete_id) ===
                      parseInt(selectedPlayer?.ifsc_id)
                  )?.rank,
                },
              });
            }}
          >
            Athlete Profile
          </GraphicButton>
        )}
        {game?.round_type === "boulder" && selectedPlayer && (
          <GraphicButton
            onClick={() => {
              previewGraphic("LTAthlete", {
                game: game,
                player: selectedPlayer,
              });
            }}
          >
            LT Athlete
          </GraphicButton>
        )}

        {game?.round_type === "boulder" && selectedPlayer && (
          <GraphicButton
            onClick={() => {
              previewGraphic("Winner", {
                game: game,
                player: selectedPlayer,
              });
            }}
          >
            Winner
          </GraphicButton>
        )}

        {/* {group?.name !== "Qualification" &&
          game?.round_type === "speed" &&
          selectedPlayer && (
            <GraphicButton
              onClick={() => {
                previewGraphic("LTMatchUp", {
                  game: game,
                  selectedHeat: selectedPlayer,
                });
              }}
            >
              LT Matchup
            </GraphicButton>
          )} */}
        {game?.round_type === "boulder" && (
          <GraphicButton
            onClick={() => {
              previewGraphic("FFMatchUp", {
                game: game,
                type: "FT",
                group,
                period,
                web: true,
              });
            }}
          >
            FF Matchup
          </GraphicButton>
        )}
        {game?.round_type === "boulder" && scoring_view === 0 && (
          <GraphicButton
            onClick={() => {
              previewGraphic("FFTable", {
                game: game,
                type: "FT",
                group,
                period,
                web: true,
              });
            }}
          >
            FF Table - Web
          </GraphicButton>
        )}
        {game?.round_type === "boulder" && scoring_view === 0 && (
          <GraphicButton
            onClick={() => {
              previewGraphic("StartList", {
                game: game,
                type: "FT",
                group,
                period,
                web: true,
              });
            }}
          >
            FF Start List
          </GraphicButton>
        )}
        {game?.round_type === "boulder" && scoring_view === 1 && (
          <GraphicButton
            onClick={() => {
              previewGraphic("FFTable", {
                game: game,
                type: "FT",
                group,
                period,
                web: false,
                ranks,
              });
            }}
          >
            FF Table - Manual
          </GraphicButton>
        )}
        {group?.name === "Qualification" &&
          game?.round_type === "speed" &&
          scoring_view === 0 && (
            <GraphicButton
              onClick={() => {
                previewGraphic("Speed FFTable", {
                  game: game,
                  type: "FT",
                  group,
                  period,
                  web: true,
                  page: 1,
                });
              }}
            >
              FF Table - Web P1
            </GraphicButton>
          )}
        {group?.name === "Qualification" &&
          game?.round_type === "speed" &&
          scoring_view === 0 && (
            <GraphicButton
              onClick={() => {
                previewGraphic("Speed FFTable", {
                  game: game,
                  type: "FT",
                  group,
                  period,
                  web: true,
                  page: 2,
                });
              }}
            >
              FF Table - Web P2
            </GraphicButton>
          )}
        {group?.name === "Qualification" &&
          game?.round_type === "speed" &&
          scoring_view === 0 && (
            <GraphicButton
              onClick={() => {
                previewGraphic("Speed FFTable", {
                  game: game,
                  type: "FT",
                  group,
                  period,
                  web: true,
                  page: 3,
                });
              }}
            >
              FF Table - Web P3
            </GraphicButton>
          )}
        {group?.name !== "Qualification" && game?.round_type === "speed" && (
          <GraphicButton
            onClick={() => {
              previewGraphic("FFBracket", {
                game: game,
                type: "FT",
                group,
                period,
                web: false,
                ranks,
              });
            }}
          >
            FF Bracket
          </GraphicButton>
        )}
      </GraphicsList>
      {graphicInPreview && (
        <CurrentGraphic>
          {graphicInPreview.name}
          <GraphicButton
            style={{ height: "40px", backgroundColor: "#383f4a" }}
            onClick={() => {
              clearPreview(graphicInPreview.name);
            }}
          >
            CLEAR PVW (CTRL + Q)
          </GraphicButton>

          <GraphicButton
            green
            style={{ height: "40px" }}
            onClick={() => {
              animateGraphic(graphicInPreview.name);
            }}
          >
            IN (F8) or (-)
          </GraphicButton>
          <GraphicButton
            red
            style={{ height: "40px" }}
            onClick={() => {
              animateOffGraphic(graphicInPreview.name);
            }}
          >
            OUT (F9) or (*)
          </GraphicButton>
        </CurrentGraphic>
      )}
    </Main>
  );
}
