import React, { useEffect, useState, useContext, useCallback } from "react";
import styled from "styled-components";
import moment from "moment";
import { UIContext, VIEW_CONSTS } from "../../contexts/UIContext";
import { DataContext } from "../../contexts/DataContext";
import { FiPlay } from "react-icons/fi";
import { AiOutlinePause } from "react-icons/ai";
import { Button } from "../../Theme/Hyper";
import { APIContext } from "../../contexts/APIContext";
import { useHistory, useLocation } from "react-router";

const Main = styled.div`
  display: flex;
  font-weight: 100;
  box-shadow: 0px 5px 3px 0 rgb(0 0 0 / 12%);
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin-top: 0.5em;
  padding-bottom: 0.5em;
  z-index: 0;
  border-bottom: 1px solid #3d485d;
`;
const ClockSection = styled.div`
  display: flex;
  min-width: 70px;
  height: 90px;
  justify-content: ${(props) => (props.mins ? "flex-end" : "flex-start")};
`;
const ClockSplit = styled.div`
  height: 90px;
`;
const Half = styled.div`
  display: flex;
  font-size: 1.5em;
`;

const ClockContainer = styled.div`
  display: flex;
  font-size: 4em;
  align-items: center;
  width: 100%;
`;
const Buttons = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5em;
  box-sizing: border-box;
`;
const ClockButton = styled.div`
  font-size: 2em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  align-items: center;
  display: flex;
  background-color: ${(props) => (props.green ? "#0adb93" : "#db0a41")};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  /* width: 100%; */
  justify-content: center;
  margin: 0.2em;
  width: 100%;
`;
const ResetButton = styled.div`
  font-size: 2em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  align-items: center;
  display: flex;
  /* width: 100%; */
  justify-content: center;
  margin: 0.2em;
  width: 70%;
  background-color: #181d25;
  margin-top: 50px;
  margin-bottom: 50px;
`;
const AddedTime = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5em;
  box-sizing: border-box;
  flex-wrap: wrap;
  justify-content: center;
`;
const AddedTimeButton = styled.div`
  font-size: 1em;
  padding: 0.5em;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  align-items: center;
  display: flex;
  background-color: ${(props) => (props.selected ? "#db0a41" : "#181d25")};

  /* width: 100%; */
  justify-content: center;
  margin: 0.2em;
  width: 25%;
  box-sizing: border-box;
  :hover {
    background-color: #db0a41;
  }
`;
const PauseResumeButton = styled(Button)`
  font-size: 0.3em;
  padding: 0.5em;
  font-weight: 100;
  margin-left: 0.5em;
  margin-right: 0.5em;
`;
const ClockDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export default function Clock({
  game,
  teams,
  previewGraphic,
  animateGraphic,
  animateOffGraphic,
  updateGraphic,
  group,
}) {
  const history = useHistory();
  const location = useLocation();
  const { setView, view } = useContext(UIContext);
  const { update, sky, kits } = useContext(DataContext);
  const { updateGame } = useContext(APIContext);
  const [clock, setClock] = useState();
  const [injuryTime, setInjuryTime] = useState(10);
  const [minutes, setMinutes] = useState();
  useEffect(() => {
    if (game) {
      let period = [...(game?.live_data?.periods || [])].sort(
        (a, b) => b.number - a.number
      )[0];

      if (period) {
        updateClock(period.kick_off, period, group);
      }
      let interval = setInterval(() => {
        if (period) {
          updateClock(period.kick_off, period, group);
        }
      }, 500);
      return () => {
        clearInterval(interval);
      };
    } else {
      updateClock(0);
    }
  }, [game, group]);

  const updateClock = useCallback(
    (koTime, period, group) => {
      let clockTime = "00:00";

      if (koTime) {
        let pauseTime = 0;
        debugger;
        period.pause &&
          period.pause.forEach((pause) => {
            if (pause.end) {
              pauseTime += pause.end - pause.start;
            }
          });
        let latestPause = period.pause && period.pause[period.pause.length - 1];
        if (latestPause && latestPause.end) {
          latestPause = null;
        }
        let time = moment.duration(
          ((latestPause && latestPause.start) || Date.now()) -
            koTime -
            pauseTime
        );

        let minutes = time.minutes() + time.hours() * 60;

        let cd_mins = group?.name === "Semi-final" ? 4 : 1;
        let cd_secs = 59;

        setMinutes(minutes);
        if (minutes < (group?.name === "Semi-final" ? 5 : 2)) {
          clockTime =
            (cd_mins - minutes).toString().padStart(2, "0") +
            ":" +
            (cd_secs - time.seconds()).toString().padStart(2, "0");
        } else {
          clockTime = "00:00";
        }

        setClock(clockTime);
      } else if (period.number) {
        let minutes = 0;
        let cd_mins = 5;

        setMinutes(minutes);

        clockTime =
          (cd_mins - Math.max(minutes, 0)).toString().padStart(2, "0") + ":00";

        setClock(clockTime);
      }
    },
    [game, updateGraphic]
  );

  function pad(num) {
    return ("0" + num).slice(-2);
  }
  function hhmmss(secs) {
    var minutes = Math.floor(secs / 60);
    secs = secs % 60;
    var hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    return { hours: pad(hours), minutes: pad(minutes), seconds: pad(secs) };
    // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
  }

  const pause = useCallback(() => {
    let new_period = { ...JSON.parse(JSON.stringify(period)) };
    if (!new_period.pause) {
      new_period.pause = [];
    }

    let newData = { ...JSON.parse(JSON.stringify(game)) };
    newData.live_data.periods = [
      {
        kick_off: new_period.kick_off,
        number: new_period.number,
        pause: [...new_period.pause, { start: moment().valueOf() }],
      },
    ];
    updateGraphic("CLOCK", {
      type: "PAUSE",
      game: newData,
      period: new_period,
    });
    updateGame({ id: game._id, live_data: newData.live_data });
  }, [game, updateGame, updateGraphic]);

  const resume = useCallback(() => {
    let new_period = { ...JSON.parse(JSON.stringify(period)) };
    if (!new_period.pause) {
      new_period.pause = [];
    }

    if (!new_period.pause[new_period.pause.length - 1].end) {
      new_period.pause[new_period.pause.length - 1].end = moment().valueOf();
    }

    let newData = { ...JSON.parse(JSON.stringify(game)) };
    newData.live_data.periods = [
      {
        kick_off: new_period.kick_off,
        number: new_period.number,
        pause: [...new_period.pause],
      },
    ];

    updateGraphic("CLOCK", {
      type: "RESUME",
      game: newData,
      period: new_period,
    });
    updateGame({ id: game._id, live_data: newData.live_data });
  }, [game, updateGame, updateGraphic]);
  function getHalfText(number, game) {
    return "";
  }

  let period = [...(game?.live_data?.periods || [])].sort(
    (a, b) => b.number - a.number
  )[0];

  return (
    <Main>
      {game && period && <Half>{getHalfText(period.number, game)}</Half>}
      {/* {" "}
        {!game.actual_kick_off && (
          <Button
            green
            onClick={() => {
              updateGame({
                uuid: game.uuid,
                data: { actual_kick_off: moment().valueOf() },
              });
            }}
          >
            KICK OFF
          </Button>
        )} */}

      {clock && (
        <ClockContainer>
          <PauseResumeButton
            onClick={() => {
              pause();
            }}
          >
            <AiOutlinePause></AiOutlinePause>
          </PauseResumeButton>

          <ClockDiv
            onClick={() => {
              if (location.pathname.indexOf("clock") > -1) {
                history.push(location.pathname.replace("/clock", ""));
              } else {
                history.push("/" + game._id + "/clock");
              }
            }}
          >
            <ClockSection mins>{clock.split(":")[0]}</ClockSection>
            <ClockSplit>:</ClockSplit>
            <ClockSection>{clock.split(":")[1]}</ClockSection>
          </ClockDiv>
          <PauseResumeButton
            onClick={() => {
              resume();
            }}
          >
            <FiPlay></FiPlay>
          </PauseResumeButton>
        </ClockContainer>
      )}
      {period && !period?.kick_off && (
        <Buttons>
          <ClockButton
            onClick={() => {
              let newData = { ...JSON.parse(JSON.stringify(game)) };
              newData.live_data.periods = [
                { kick_off: moment().valueOf(), number: period.number },
              ];
              updateGame({ id: game._id, live_data: newData.live_data });
            }}
          >
            Start
          </ClockButton>
        </Buttons>
      )}
      {period && period?.kick_off && (
        <Column>
          <Buttons>
            <ClockButton
              onClick={() => {
                animateOffGraphic("CLOCK", {});
              }}
            >
              OUT
            </ClockButton>

            <ClockButton
              onClick={() => {
                animateGraphic("CLOCK", {
                  teams: teams,
                  game: game,
                  clock: clock,
                  period: period,
                  sky: sky,
                  kits: kits,
                  added_time: period.added_time,
                });
              }}
              green
            >
              IN
            </ClockButton>
          </Buttons>
        </Column>
      )}
      <ResetButton
        onClick={() => {
          let newData = { ...JSON.parse(JSON.stringify(game)) };
          if (!newData.live_data) {
            newData.live_data = { periods: [] };
          }
          newData.live_data.periods = newData.live_data?.periods?.filter(
            (p) => p.number < 1
          );
          newData.live_data.periods.push({
            kick_off: null,
            number: 1,
          });
          updateGraphic("CLOCK", {
            game: game,
            period: { kick_off: null, number: 1 },
          });
          updateGame({ id: game._id, live_data: newData.live_data });
        }}
      >
        Reset
      </ResetButton>
    </Main>
  );
}
